<template>
  <HXContentCard title="Editar Ciclo" :toBack="{ name: 'Ciclo_evaluaciones' }">
    <card class="shadow-none">
      <h4 class="px-4 mt-3"><b>Información General</b></h4>
      <b-form @submit.prevent="saveData()">
        <div class="row p-4">
          <div class="col-xl-12">
            <div>
              <label for=" " class="form-label">Titulo del Ciclo</label>
              <input
                required
                v-model="row.nombre"
                placeholder="Escriba nombre del ciclo..."
                type="text"
                class="form-control"
                id=" "
              />
            </div>
          </div>
          <!-- <div class="col-xl-6">
              <div class="mb-3">
                <label for=" " class="form-label">Empresa</label>
                <b-form-select v-model="selected" :options="options"></b-form-select>
              </div>
            </div> -->

          <div class="col-xl-12 mb-4">
            <label for=" " class="form-label">Descripción</label>
            <b-form-textarea
              required
              v-model="row.descripcion"
              max-rows="5"
              id="textarea-default"
              placeholder="Escriba una descripción de la competencia..."
            ></b-form-textarea>
          </div>

          <div class="col-xl-6">
            <div>
              <label for=" " class="form-label">Fecha inicial</label>
              <!-- <input placeholder="Fecha inicial" type="date" class="form-control" id=" " /> -->
              <input
                required
                v-model="row.fechaDesde"
                type="datetime-local"
                class="form-control"
                id=""
              />
            </div>
          </div>
          <div class="col-xl-6 mb-4">
            <div>
              <label for=" " class="form-label">Fecha final</label>
              <input
                required
                v-model="row.fechaHasta"
                type="datetime-local"
                class="form-control"
                id=""
              />
            </div>
          </div>
        </div>
        <div class="text-right px-5 pb-4">
          <b-button class="mx-1 px-4 py-2" variant="primary"
            >Guardar Ciclo</b-button
          >
        </div>
      </b-form>
    </card>
  </HXContentCard>
</template>
<script>
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      id: null,
      row: {},
    };
  },
  async mounted() {
    this.$isLoading(true);
    core.index();
    window.$("#datatable_empresa").DataTable();
    this.id = this.$route.params.id;
    await this.getData();
    this.$isLoading(false);
  },
  methods: {
    cancelar() {
      this.$router.push("/helex/ciclo_evaluaciones");
    },
    async saveData() {
      try {
        await this.$store.dispatch("hl_post", {
          path: "CicloEvaluacion/Update",
          data: this.row,
        });
        Swal.fire({
          title: "Listo!",
          text: "Datos almacenados correctamente",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push("/helex/ciclo_evaluaciones");
          }
        });
      } catch (error) {
        console.log("err", error);
        Swal.fire({
          title: "¡No Hemos Registrado!",
          text:
            "Por favor revisa la información ingresada, si el problema persiste, contacta con el soporte técnico. Código de error: " +
            error.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    },
    async getData() {
      let path = "CicloEvaluacion/GetCicloEvaluacion/" + this.id;
      // let response = await this.$store.getters.fetchGet({ path: path });
      let response = await this.$store.dispatch("hl_get", {
        path: path,
      });
      this.row = response;
      console.log("ciclo", this.row);
    },
  },
};
</script>
